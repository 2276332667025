<template>
  <div>
    <div class="page-body">
      <title-bar title="单位管理">
        <el-button type="primary" @click="edit()" icon="fa fa-plus-circle" :loading="loading">
          添加
        </el-button>
      </title-bar>
      <el-form ref="queryForm" :model="queryForm" inline label-width="100px">
        <el-form-item label="单位性质">
          <code-select code-type="orgType" v-model="queryForm.orgType" @change="query"></code-select>
        </el-form-item>
        <el-form-item label="单位层级">
          <code-select code-type="orgLevel" v-model="queryForm.orgLevel" @change="query"></code-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input type="text" v-model="queryForm.orgName" maxlength="50"
                    placeholder="请输入单位名称或编码模糊查询" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item class="button-container" label-width="0px">
          <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
<!--        <el-table-column prop="orgId" label="单位编码" align="center" min-width="120"></el-table-column>-->
        <el-table-column prop="orgName" label="单位名称" min-width="300"></el-table-column>
<!--        <el-table-column prop="orgLevel" label="单位层级" align="center" min-width="90">-->
<!--          <template slot-scope="{ row, $index }">-->
<!--            <span v-if="row.orgLevel == 0">部级</span>-->
<!--            <span v-else-if="row.orgLevel == 1">省级</span>-->
<!--            <span v-else-if="row.orgLevel == 2">市级</span>-->
<!--            <span v-else-if="row.orgLevel == 3">区县级</span>-->
<!--            <span v-else class="color-error">未配置</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="orgType" label="单位性质" align="center" min-width="100">
          <template slot-scope="{ row, $index }">
            <span v-if="row.orgType == '0'">水利部机关</span>
            <span v-else-if="row.orgType == '1'">水利部直属单位</span>
            <span v-else-if="row.orgType == '2'">地方水利系统</span>
            <span v-else-if="row.orgType == '3'">其他单位</span>
            <span v-else class="color-error">未配置</span>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="所属区域" min-width="200"></el-table-column>
        <el-table-column prop="userCount" label="注册人数" align="center" min-width="80"></el-table-column>
        <el-table-column prop="valid" label="状态" align="center" width="70">
          <template slot-scope="{ row, $index }">
            <i class="fa fa-check-circle color-success" v-if="row.valid"></i>
            <i class="fa fa-times-circle color-error" v-else></i>
          </template>
        </el-table-column>
        <el-table-column prop="log" label="操作" width="180" align="center">
          <template slot-scope="{ row, $index }">
            <el-button type="primary" size="small" @click="edit(row)">
              编辑
            </el-button>
            <el-button type="primary" size="small" @click="moveUsers(row)">
              迁移用户
            </el-button>
            <el-button type="danger" size="small" @click="del(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>
    </div>

    <org-choose-dialog v-if="moveUsersFlag" :visible.sync="moveUsersFlag" @select="doMoveUsers"></org-choose-dialog>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";
import OrgChooseDialog from "@/components/OrgChooseDialog";

export default {
  components: {OrgChooseDialog, CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      records: [],
      total: 0,
      moveUsersFlag: false,
      selectedOrg: null,
      queryForm: {
        orgName: "",
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.postForm("/org/list", this.queryForm, true).then(dr => {
        this.records = dr.rows;
        this.total = dr.total;
      });
      this.loading = false;
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    //修改
    edit(row) {
        this.$router.push("/orgEdit?orgId="+(row?row.orgId:""));
    },
    //删除
    del(row) {
      this.$confirm("确定要删除选中单位吗？","系统提示", {type:"warning"}).then(() => {
        this.loading = true;
        this.postForm("/org/delete", {
          orgId: row.orgId
        }, true).then((dr) => {
          this.loadData();
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    //迁移用户
    moveUsers(row) {
      this.selectedOrg = row;
      this.moveUsersFlag = true;
    },
    doMoveUsers(row) {
      this.$confirm("确定要将“"+this.selectedOrg.orgName+"”下用户迁移至“"+row.orgName+"”吗？","系统提示", {type:"warning"}).then(() => {
        this.moveUsersFlag = false;
        this.loading = true;
        this.postForm("/org/move-users", {
          orgId: this.selectedOrg.orgId,
          toOrgId: row.orgId
        }, true).then((dr) => {
          this.loadData();
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
